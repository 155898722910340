import React from "react"
import { graphql } from "gatsby"
import { Router } from "@reach/router"

import Recipe from "../components/recipe"

const BlogPostTemplate = ({ data: { post } }) => {

  const featuredImage = {
    image: post.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData,
    alt: post.featuredImage?.node?.altText || ``,
    src: post.featuredImage?.node?.localFile?.childImageSharp?.fixed.src,
    height: post.featuredImage?.node?.localFile?.childImageSharp?.fixed.height,
    width: post.featuredImage?.node?.localFile?.childImageSharp?.fixed.width,
  }

  return (
     <>
      <Router>
        <Recipe path={post.uri} post={post} featuredImage={featuredImage} />
      </Router>
    </>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostById(
    # these variables are passed in via createPage.pageContext in gatsby-node.js
    $id: String!
  ) {
    # selecting the current post by id
    post: wpPost(id: { eq: $id }) {
      id
      uri
      slug
      excerpt
      content
      title
      date(formatString: "MMMM DD, YYYY")
      categories {
        nodes {
          uri
          name
        }
      }
      featuredImage {
        node {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData
              fixed {
                src
                width
                height
              }
            }
          }
        }
      }
    }
  }
`