import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import parse from "html-react-parser";
import { Row, Container, Col, Breadcrumb, Alert } from "react-bootstrap";
import { ImArrowDown } from "react-icons/im";
import { useSelector } from "react-redux";
import { store } from "../redux-store";
import { setDoShowSignIn } from "../actions/user-action";
import Badge from 'react-bootstrap/Badge'

import Layout from "../components/layout";
import Seo from "../components/seo";
import SocialMedia from "../components/socialMedia";
import RecipePrint from "../components/recipePrint";
import { callAPI } from "./api";
import { isUserSignedIn } from "./accountManagement";
import { Comments } from "../components/comments";

const Recipe = ({ post, featuredImage, location }) => {
  const print_mode = location.search === "?print";
  console.log('post.slug='+post.slug);
  console.log('post.title='+post.title);

  const [bannerMessage, setBannerMessage] = useState("");

  var sections = ["", ""];

  const addRecipeSubtitleClass = (content, subtitle) => {
    const pos = content.indexOf(subtitle);
    if (pos !== -1) {
      content =
        content.substr(0, pos) +
        '<span className="recipe-subtitle">' +
        subtitle +
        "</span>" +
        content.substr(pos + subtitle.length);
    }
    return content;
  };

  var content = post.content;
  if (!!content) {
    const pos = content.indexOf("<strong>INGREDIENTS</strong>");
    //const pos = 1;
    //console.log('pos='+pos);
    sections[1] = content;
    //var content_part2 = "";
    if (pos !== -1) {
      sections[1] = content.substring(0, pos);
      sections[2] = content.substring(pos);
      //content_part2 = '<span className="recipe-subtitle">INGREDIENTS</span>'+content.substr(pos+28);
    } else {
      //console.log('recipe has no INGREDIENT title: '+post.title)
    }

    for (var i = 0; i < sections.length; i++) {
      sections[i] = addRecipeSubtitleClass(sections[i], "<strong>DESCRIPTION</strong>");
      sections[i] = addRecipeSubtitleClass(sections[i], "<strong>INGREDIENTS</strong>");
      sections[i] = addRecipeSubtitleClass(sections[i], "<strong>DIRECTIONS</strong>");
    }
  }

  const LocalSocialMedia = () => {
    const userData = useSelector((state) => state.userData);
    const [savedRecipe, setSavedRecipe] = useState(false);

    useEffect(() => {
      const querySavedRecipe = async (slug) => {
        var result = await callAPI("/saved-recipes/ilir-recipe/" + slug, "GET", null, userData, store, true);
        if (result.status === "success") {
          //console.log('api results='+result);
          setSavedRecipe(true);
        } else {
          if (result.apiStatus === 400) {
            if (result.data.detail === "SAVED_RECIPE_NOT_EXISTS") {
              setSavedRecipe(false);
            } else {
              console.error("Error:", result.message);
            }
          } else {
            console.error("Error:", result.message);
          }
        }
      };
      //console.log('***********in querySavedRecipe');
      if (isUserSignedIn(userData)) {
        querySavedRecipe(post.slug);
      } else {
        setSavedRecipe(false);
      }
    }, [userData]);

    const click_save_recipe = async () => {
      //console.log('savedRecipe='+savedRecipe);

      if (isUserSignedIn(userData)) {
        let data = { recipe_for_name: post.title };
        var action = savedRecipe ? "DELETE" : "POST";
        var result = await callAPI("/saved-recipes/ilir-recipe/" + post.slug, action, data, userData, store, true);
        if (result.status === "success") {
          //console.log('api results='+result);
          setSavedRecipe(!savedRecipe);
          setBannerMessage("");
        } else {
          setBannerMessage("Error: " + result.message);
        }
      } else {
        store.dispatch(setDoShowSignIn(true));
      }
    };

    return (
      <SocialMedia
        uri={"https://www.iloveitrecipes.com" + post.uri}
        includePrint={true}
        includeReview={true}
        includeSave={true}
        savedValue={savedRecipe}
        saveCallBack={click_save_recipe}
      />
    );
  };

  const RatingHeader = () => {
    const userData = useSelector((state) => state.userData);
    const [rating, setRating] = useState(-1);
    const [ratingCount, setRatingCount] = useState(-1);
    const [reviewCount, setReviewCount] = useState(-1);

    useEffect(() => {
      const getRecipeStats = async (slug) => {
        //console.log('*************in getRecipeStats');
        var result = await callAPI("/recipes/ilir-recipe/" + slug, "GET", null, userData, store, false);
        if (result.status === "success") {
          //console.log('in getRecipeStats')
          setRating(result.data.rating);
          setRatingCount(result.data.rating_count);
          setReviewCount(result.data.comment_count);
        } else {
          if (result.apiStatus === 400) {
            if (result.data.detail === "RECIPE_NOT_EXISTS") {
              setRatingCount(0);
              setReviewCount(0);
              //setRating(4);  // TODO: Remove, just testing
            } else {
              console.error("Error:", result.data.detail);
            }
          } else {
            console.error("Error:", result.message);
          }
        }
      };
      getRecipeStats(post.slug);
    }, [userData]);

    var stars = <></>;

    if (rating >= 0) {
      var rating_int = Math.floor(rating);
      var rating_fraction = rating - rating_int;
      var star_fraction = "";
      if (rating_fraction < 0.1) {
        star_fraction = "rating-empty-star";
      } else if (rating_fraction < 0.4) {
        star_fraction = "rating-star-30";
      } else if (rating_fraction < 0.7) {
        star_fraction = "rating-star-50";
      } else {
        star_fraction = "rating-star-70";
      }

      for (var x = 0; x < rating_int; x++) {
        stars = (
          <>
            {stars}
            <div className="rating-star"></div>
          </>
        );
      }
      if (rating < 5) {
        stars = (
          <>
            {stars}
            <div className={star_fraction}></div>
          </>
        );
        if (rating < 4) {
          for (var y = rating_int + 1; y < 5; y++) {
            stars = (
              <>
                {stars}
                <div className="rating-empty-star"></div>
              </>
            );
          }
        }
      }
    }

    return (
      <div id="recipe-stats" className="d-flex mb-3">
        {ratingCount === -1 ? (
          <></>
        ) : (
          <>
            <div className="d-flex flex-wrap">
              {rating >= 0 ? (
                <>
                  <div>
                    <Link to="#reviews">
                      <div className="me-1">{stars}</div>
                    </Link>
                  </div>
                  <div>
                    <Link to="#reviews">
                      <div className="me-3 rating-value">{parseFloat(rating).toFixed(1)}</div>
                    </Link>
                  </div>
                </>
              ) : (
                <></>
              )}
              <div className="me-3">
                <Link to="#reviews" className="underline-link">
                  <span className="me-3">
                    {ratingCount} RATING{ratingCount === 1 ? "" : "S"}
                  </span>
                  <span>
                    {reviewCount} REVIEW{reviewCount === 1 ? "" : "S"}
                  </span>
                </Link>
              </div>
            </div>
            <div className="mt-1">
              <Link to="#recipe" className="link-no-bold">
                <div className="jump-to-recipe-button text-center align-items-center">
                  JUMP TO RECIPE <ImArrowDown />
                </div>
              </Link>
            </div>
          </>
        )}
      </div>
    );
  };

  return print_mode ? (
    <RecipePrint post={post} featuredImage={featuredImage} />
  ) : (
    <>
      <Layout pageInfo={{ pageName: post.title || " Recipe" }}>
        <Seo
          title={post.title}
          keywords={[`recipe`]}
          description={post.excerpt}
          imageSrc={featuredImage.src}
          imageWidth={featuredImage.width}
          imageHeight={featuredImage.height}
          imageAlt={featuredImage.alt}
          url={post.uri}
        />

        <Container fluid>
          <Row className="justify-content-left my-3">
          <Breadcrumb>
            <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
            <Breadcrumb.Item href="/recipe-index">Recipes</Breadcrumb.Item>
            <Breadcrumb.Item active>{post.title}</Breadcrumb.Item>
          </Breadcrumb>
          </Row>
          <Row>
            {bannerMessage === "" ? (
              <></>
            ) : (
              <Alert className="ms-2 me-2 mt-2" variant="warning">
                {bannerMessage}
              </Alert>
            )}
          </Row>
        </Container>
        <Container>
          <Row>
            <div className="d-flex flex-row justify-content-between pb-4 pe-4">
              <h1 itemProp="headline" className="d-flex flex-shrink-1">
                {parse(post.title)}
              </h1>
            </div>
          </Row>
          <Row>
            <RatingHeader />
          </Row>

          <Row>
            <Col>
              <article className="blog-post" itemScope itemType="http://schema.org/Article">
                <header>
                  <LocalSocialMedia />

                  {/* if we have a featured image for this post let's display it */}
                  {featuredImage?.image && (
                    <GatsbyImage image={featuredImage.image} alt={featuredImage.alt} style={{ marginBottom: 10 }} />
                  )}
                    <div id="recipe-category-list" className="d-flex flex-wrap mb-2">
                      {post.categories.nodes.map( category => (
                      <Link to={category.uri} key={category.uri} className="m-1"><Badge bg="secondary" >{category.name}</Badge></Link>
                    ))}
                    </div>
                </header>

                {!!content && (
                  <section itemProp="articleBody" className="recipe-body">
                    {parse(sections[1])}
                    <div id="recipe" className="scroll-anchor">&nbsp;</div>
                    <div>{sections[2] && <>{parse(sections[2])}</>}</div>
                  </section>
                )}
                
                <section itemProp="articleBody" className="recipe-body">
                  <Comments commentFor="ilir-recipe" commentForId={post.slug} commentForName={post.title} />
                </section>

                <hr />

                <footer></footer>
              </article>
            </Col>
          </Row>
        </Container>
      </Layout>
    </>
  );
};

export default Recipe;
